import { For, Show } from "solid-js";
import { RouteSectionProps } from "@solidjs/router";

import { css } from "#style/css";
import { vstack } from "#style/patterns";
import { Grid, VStack, Box, styled } from "#style/jsx";

import { AppPageContainer } from "#root/components/AppPageContainer";
import { AppDashboardHeader } from "#root/components/AppDashboardHeader";
import { Button } from "#root/components/Button";
import { Typography } from "#root/components/Typography";
import { useSessions } from "#root/domain/session";
import { formatDateFromISO } from "#root/module/date";
import { useTranslation } from "#root/domain/i18n";

export default function AppDashboardPage(props: RouteSectionProps) {
  const sessions = useSessions();
  const { t } = useTranslation();

  return (
    <AppPageContainer>
      <AppDashboardHeader pageTitle={t("dashboard.contentTitle")}>
        <Button tag="a" href="/app/dashboard/newSession" size="lg" ctx="dark">
          {t("dashboard.startNewSession")}
        </Button>
      </AppDashboardHeader>
      <VStack
        w="full"
        justifyContent="flex-start"
        alignItems="flex-start"
        py="10"
        gap="10"
      >
        <Typography
          textStyle="heading1"
          tag="h2"
          class={vstack({
            alignItems: "flex-start",
            gap: "0",
          })}
        >
          <styled.span color="white">
            {t("dashboard.pastSessionTitle")[0]}
          </styled.span>
          <styled.span color="lightGray">
            {t("dashboard.pastSessionTitle")[1]}
          </styled.span>
        </Typography>

        <Grid
          gridTemplateColumns="1fr"
          gap="8"
          w="full"
          md={{
            gridTemplateColumns: "1fr 1fr",
          }}
          lg={{
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <Show when={sessions.data}>
            {(data) => (
              <For
                each={data()}
                fallback={<div>{t("dashboard.emptySessionList")}</div>}
              >
                {(item) => (
                  <SessionCard
                    id={item.id}
                    title={item.name}
                    createdAt={item.insertedAt}
                    questionsCount={item.questionsCount}
                    isActive={item.doneAt == null}
                  />
                )}
              </For>
            )}
          </Show>
        </Grid>
      </VStack>
      {props.children}
    </AppPageContainer>
  );
}

type SessionCardProps = {
  id: string;
  title: string;
  questionsCount: number;
  createdAt: string;
  isActive: boolean;
};

function SessionCard(props: SessionCardProps) {
  const { t } = useTranslation();

  return (
    <styled.article
      display="flex"
      flexDir="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap="10"
      w="full"
      p="6"
      backgroundColor="darkGray"
      borderRadius="lg"
    >
      <VStack
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap="4"
      >
        <Typography
          tag="h3"
          textStyle="smallImportant"
          class={css({ color: "white" })}
        >
          {props.title}
        </Typography>

        <CardStatus status={props.isActive ? "connected" : "ended"} />
      </VStack>

      <styled.dl
        w="full"
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        gap="10"
      >
        <Box flexGrow="1" flexShrink="0">
          <Typography
            tag="dt"
            textStyle="xxsmall"
            class={css({
              color: "lightGray",
            })}
          >
            {t("dashboard.sessionCard.startedOn")}
          </Typography>
          <Typography
            tag="dd"
            textStyle="small"
            class={css({ color: "white" })}
          >
            {formatDateFromISO(props.createdAt)}
          </Typography>
        </Box>

        <Box flexGrow="1" flexShrink="0">
          <Typography
            tag="dt"
            textStyle="xxsmall"
            class={css({
              color: "lightGray",
            })}
          >
            {t("dashboard.sessionCard.questions")}
          </Typography>
          <Typography
            tag="dd"
            textStyle="small"
            class={css({ color: "white" })}
          >
            {props.questionsCount}
          </Typography>
        </Box>
      </styled.dl>
      <Button
        tag="a"
        href={`/app/sessions/${props.id}`}
        color="yellow"
        status="secondary"
        ctx="dark"
      >
        {t("dashboard.sessionCard.details")}
      </Button>
    </styled.article>
  );
}

type CardStatusProps = {
  status: "connected" | "ended";
};
function CardStatus(props: CardStatusProps) {
  const { t } = useTranslation();

  function getWording() {
    switch (props.status) {
      case "ended":
        return t("dashboard.cardStatus.ended");
      case "connected":
        return t("dashboard.cardStatus.connected");
    }
  }

  return (
    <Box
      borderRadius="md"
      p="2"
      backgroundColor={props.status === "connected" ? "green.700" : "red.700"}
    >
      <Typography textStyle="xxsmall" class={css({ color: "white" })}>
        {getWording()}
      </Typography>
    </Box>
  );
}
